
export const toUpper = n => {
  const parts = n.split(' ');
  const upperParts = parts.map(part => part[0].toUpperCase() + part.substring(1))
  return upperParts.join(' ');
}

export const STATE_LOOKUP = {
  "Alabama": "AL",
  "Kentucky": "KY",
  "Ohio": "OH",
  "Alaska": "AK",
  "Louisiana": "LA",
  "Oklahoma": "OK",
  "Arizona": "AZ",
  "Maine": "ME",
  "Oregon": "OR",
  "Arkansas": "AR",
  "Maryland": "MD",
  "Pennsylvania": "PA",
  "American Samoa": "AS",
  "Massachusetts": "MA",
  "Puerto Rico": "PR",
  "California": "CA",
  "Michigan": "MI",
  "Rhode Island": "RI",
  "Colorado": "CO",
  "Minnesota": "MN",
  "South Carolina": "SC",
  "Connecticut": "CT",
  "Mississippi": "MS",
  "South Dakota": "SD",
  "Delaware": "DE",
  "Missouri": "MO",
  "Tennessee": "TN",
  "District of Columbia": "DC",
  "Montana": "MT",
  "Texas": "TX",
  "Florida": "FL",
  "Nebraska": "NE",
  "Trust Territories": "TT",
  "Georgia": "GA",
  "Nevada": "NV",
  "Utah": "UT",
  "Guam": "GU",
  "New Hampshire": "NH",
  "Vermont": "VT",
  "Hawaii": "HI",
  "New Jersey": "NJ",
  "Virginia": "VA",
  "Idaho": "ID",
  "New Mexico": "NM",
  "Virgin Islands": "VI",
  "Illinois": "IL",
  "New York": "NY",
  "Washington": "WA",
  "Indiana": "IN",
  "North Carolina": "NC",
  "West Virginia": "WV",
  "Iowa": "IA",
  "North Dakota": "ND",
  "Wisconsin": "WI",
  "Kansas": "KS",
  "Northern Mariana Islands": "MP",
  "Wyoming": "WY"
}

export const STATE_LOOKUP_REV = {
  "abb": "long",
  "AL": "Alabama",
  "KY": "Kentucky",
  "OH": "Ohio",
  "AK": "Alaska",
  "LA": "Louisiana",
  "OK": "Oklahoma",
  "AZ": "Arizona",
  "ME": "Maine",
  "OR": "Oregon",
  "AR": "Arkansas",
  "MD": "Maryland",
  "PA": "Pennsylvania",
  "AS": "American Samoa",
  "MA": "Massachusetts",
  "PR": "Puerto Rico",
  "CA": "California",
  "MI": "Michigan",
  "RI": "Rhode Island",
  "CO": "Colorado",
  "MN": "Minnesota",
  "SC": "South Carolina",
  "CT": "Connecticut",
  "MS": "Mississippi",
  "SD": "South Dakota",
  "DE": "Delaware",
  "MO": "Missouri",
  "TN": "Tennessee",
  "DC": "District of Columbia",
  "MT": "Montana",
  "TX": "Texas",
  "FL": "Florida",
  "NE": "Nebraska",
  "TT": "Trust Territories",
  "GA": "Georgia",
  "NV": "Nevada",
  "UT": "Utah",
  "GU": "Guam",
  "NH": "New Hampshire",
  "VT": "Vermont",
  "HI": "Hawaii",
  "NJ": "New Jersey",
  "VA": "Virginia",
  "ID": "Idaho",
  "NM": "New Mexico",
  "VI": "Virgin Islands",
  "IL": "Illinois",
  "NY": "New York",
  "WA": "Washington",
  "IN": "Indiana",
  "NC": "North Carolina",
  "WV": "West Virginia",
  "IA": "Iowa",
  "ND": "North Dakota",
  "WI": "Wisconsin",
  "KS": "Kansas",
  "MP": "Northern Mariana Islands",
  "WY": "Wyoming",
}